<template>
  <v-app
    id="app"
    :class="{
      [`v-application--${themeName}`]: true,
      [`v-application--3ds-mode`]: is3DSMode,
    }"
  >
    <router-view />
  </v-app>
</template>

<script>
import { getFavicon } from '@/helpers/favicon-by-brand.js';
import { appThemeFontFamily } from '@/helpers/app-theme.js';
import { getUrlParameter } from '@/helpers/url-parameter';
import { mapState } from 'vuex';
import { THEME } from './helpers/app-theme';

export default {
  metaInfo: {
    title: 'Payment form',
    titleTemplate: '%s | Payment form',
  },
  data() {
    return {};
  },
  computed: {
    ...mapState({
      is3DsInProcess: ({ is3DsInProcess }) => is3DsInProcess,
    }),
    themeName() {
      return getUrlParameter('theme') || '';
    },
    is3DSMode() {
      return (
        this.is3DsInProcess &&
        (THEME.TRIPPLE_SEVEN_BL === this.themeName ||
          THEME.SLOTOKING_BL === this.themeName)
      );
    },
  },
  mounted() {
    const fontFamily = appThemeFontFamily();
    if (fontFamily)
      document.getElementById('app').style.fontFamily = fontFamily;

    const fav = document.getElementById('favicon');
    fav.href = `${window.location.origin}/${getFavicon()}`;
  },
};
</script>

<style lang="scss" scoped>
html,
body,
html body .v-application {
  color: var(--v-color-base);
  background: var(--v-background-base);
}

html body .v-application--Vegas-Black {
  background: linear-gradient(151.65deg, #001b24 11.71%, #003d52 82.48%);
  font-variant-numeric: lining-nums proportional-nums;
}

html body .v-application--3ds-mode {
  background: #c0c0cd;
}
</style>
