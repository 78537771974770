export const getUrlParameter = (name) => {
  return new URLSearchParams(
    new URL(window.location).hash.slice(1).split('?')[1],
  ).get(name);
};

export const getAllUrlParameters = () => {
  const result = {};
  const brand = getUrlParameter('brand');
  if (brand) result.brand = brand;
  const theme = getUrlParameter('theme');
  if (theme) result.theme = theme;
  const locale = getUrlParameter('locale');
  if (locale) result.locale = locale;

  return result;
};
