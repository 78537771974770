export async function safeAssignmentOperator(callback) {
  try {
    const result = await callback();
    return [null, result];
  } catch (error) {
    return [error, null];
  }
}

export function safeAssignmentOperatorSync(callback) {
  try {
    const result = callback();
    return [null, result];
  } catch (error) {
    return [error, null];
  }
}
