import { getUrlParameter } from './url-parameter';

export const THEME = {
  SLOTOKING_WH: 'Slotoking-White',
  SLOTOKING_OUTDATED: 'Slotoking',
  SLOTOKING_BL: 'Slotoking-Black',
  TRIPPLE_SEVEN_WH: '777-White',
  TRIPPLE_SEVEN_WHITE_OUTDATED: '777',
  TRIPPLE_SEVEN_BL: '777-Black',
  NLC: 'NLC',
  NLC_V2: 'NLC_V2',
  NLC_OUTDATED: 'Nolimitcoin',
  FORTUNE_WHEELZ: 'FW',
  FORTUNE_WHEELZ_OUTDATED: 'FortuneWheelz',
  FORTUNE_WHEELZ_VER2: 'FortuneWheelz_ver2',
  JACKPOTT_RABBIT: 'JR',
  JACKPOTT_RABBIT_OUTDATED: 'JackpottRabbit',
  VEGAS_BL: 'Vegas-Black',
  WILDWINZ_BL: 'WW-Black',
  FCITY: 'FCITY',
  SCARLET_BL: 'ScarletSands-Black',
  FUNRIZE_BL: 'Funrize-Black',
};

export function appTheme() {
  const theme = getUrlParameter('theme');

  switch (theme) {
    case THEME.SLOTOKING_WH:
    case THEME.SLOTOKING_OUTDATED:
      return slotoKingTheme();
    case THEME.SLOTOKING_BL:
      return slotoKingBlackTheme();
    case THEME.TRIPPLE_SEVEN_WH:
    case THEME.TRIPPLE_SEVEN_WHITE_OUTDATED:
      return thrippleSevenWhiteTheme();
    case THEME.TRIPPLE_SEVEN_BL:
      return trippleSevenBlackTheme();
    case THEME.JACKPOTT_RABBIT:
    case THEME.JACKPOTT_RABBIT_OUTDATED:
      return jackpottRabbitTheme();
    case THEME.NLC:
    case THEME.NLC_OUTDATED:
      return nlcTheme();
    case THEME.NLC_V2:
      return nlcV2Theme();
    case THEME.FORTUNE_WHEELZ:
    case THEME.FORTUNE_WHEELZ_OUTDATED:
      return fortuneWheelzTheme();
    case THEME.FORTUNE_WHEELZ_VER2:
      return fortuneWheelzVer2Theme();
    case THEME.VEGAS_BL:
      return vegasBlackTheme();
    case THEME.WILDWINZ_BL:
      return wildWinzBlackTheme();
    case THEME.FCITY:
      return fcityTheme();
    case THEME.SCARLET_BL:
      return scarlettBlackTheme();
    case THEME.FUNRIZE_BL:
      return funrizeBlackTheme();
    default:
      return slotoKingTheme();
  }
}

export const appThemeFontFamily = () => {
  const theme = getUrlParameter('theme');

  switch (theme) {
    case THEME.SLOTOKING_WH:
    case THEME.SLOTOKING_OUTDATED:
      return 'Roboto';
    case THEME.SLOTOKING_BL:
      return 'OpenSans';
    case THEME.TRIPPLE_SEVEN_WH:
    case THEME.TRIPPLE_SEVEN_WHITE_OUTDATED:
      return 'Geologica';
    case THEME.TRIPPLE_SEVEN_BL:
      return 'OpenSans';
    case THEME.NLC:
    case THEME.NLC_OUTDATED:
    case THEME.NLC_V2:
      return 'ReadexPro';
    case THEME.FORTUNE_WHEELZ:
    case THEME.FORTUNE_WHEELZ_OUTDATED:
      return 'Rubik';
    case THEME.FORTUNE_WHEELZ_VER2:
      return 'Poppins';
    case THEME.JACKPOTT_RABBIT:
    case THEME.JACKPOTT_RABBIT_OUTDATED:
      return 'WorkSans';
    case THEME.VEGAS_BL:
      return 'Raleway';
    case THEME.WILDWINZ_BL:
      return 'Jost';
    case THEME.FCITY:
      return 'Montserrat';
    case THEME.SCARLET_BL:
      return 'DMSans';
    case THEME.FUNRIZE_BL:
      return 'Outfit';
    default:
      return 'Roboto';
  }
};

export const appThemeName = (shop) =>
  getUrlParameter('theme') || shop?.theme || '';

function scarlettBlackTheme() {
  return {
    dark: true,
    colors: {
      // primary: "#",
      // secondary: "#",
      color: '#FFF',
      background: '#05050F',
      cardSecondaryColor: '#A6A6C0',
      cardBg: '#595971',
      inputColor: '#F8F8FF',
      inputLabel: '#8B8BA5',
      inputLabelAsteriks: '#8B8BA5',
      inputPlaceholderColor: '#A6A6C0',
      inputBorder: '#595971',
      inputBorderFocused: '#757592', // custom
      inputBorderError: '#7D0000',
      inputBackground: '#202036',
      inputBackgroundError: '#000',
      inputBackgroundHover: '#595971',
      inputBackgroundFocus: '#595971',
      submitColor: '#FFF',
      submitBg: '#DB0037',
      submitHoverBg: '#f8003e', // custom
      submitActiveBg: '#ff1953', //  custom
      submitDisabledBg: '#DB0037',
      submitDisabledColor: '#72728B',
      // submitBorder: "#",
      // submitBorderDisabled: "#",
      // submitBoxShadow: "#000",
      publicOffer: '#72728B',
      linkColor: '#A6A6C0',
      linkColorHover: '#b8b8cd',
      linkColorActive: '#acacc4',
      loaderColor: '#FFE229',
      error: '#BB1515',
      errorColorText: '#F8F8FF',
      errorPlaceholderColorText: '#A6A6C0',
    },
  };
}

function slotoKingTheme() {
  return {
    dark: false,
    colors: {
      // primary: "#",
      // secondary: "#",
      color: '#4E4D5E',
      background: '#fff',
      cardSecondaryColor: '#4E4D5E',
      cardBg: '#FFF',
      inputColor: '#4E4D5E',
      inputLabel: '#4E4D5E',
      inputLabelAsteriks: '#E05252',
      inputPlaceholderColor: '#B3B3B3',
      inputBorder: '#B3B3B3',
      inputBorderFocused: '#6b6b6b',
      inputBorderError: '#B3B3B3',
      inputBackground: '#FFF',
      inputBackgroundError: '#FFF',
      inputBackgroundHover: '#FFF',
      inputBackgroundFocus: '#FFF',
      submitColor: '#4E4D5E',
      submitBg: '#FFE600',
      submitHoverBg: '#e5ce00',
      submitActiveBg: '#bfac00',
      submitDisabledBg: '#fff499',
      submitDisabledColor: '#B3ADCC',
      // submitBorder: "#",
      // submitBorderDisabled: "#",
      // submitBoxShadow: "#000",
      publicOffer: '#B3B3B3',
      linkColor: '#6969cd',
      linkColorHover: '#5b5ba8',
      linkColorActive: '#8989d7',
      loaderColor: '#FFE600',
      error: '#E05252',
      errorColorText: '#4E4D5E',
      errorPlaceholderColorText: '#B3B3B3',
    },
  };
}

function slotoKingBlackTheme() {
  return {
    dark: true,
    colors: {
      // primary: "#",
      // secondary: "#",
      color: '#AEB9D1',
      background: '#060a0e',
      cardSecondaryColor: '#AEB9D1',
      cardBg: '#1C2331',
      inputColor: '#E8ECF2',
      inputLabel: '#8D9EBE',
      inputLabelAsteriks: '#E05252',
      inputPlaceholderColor: '#88A2BF',
      inputBorder: '#2E3A51',
      inputBorderFocused: '#3c4b69',
      inputBorderError: '#2E3A51',
      inputBackground: '#1C2331',
      inputBackgroundError: '#1C2331',
      inputBackgroundHover: '#1C2331',
      inputBackgroundFocus: '#1C2331',
      submitColor: '#1C2331',
      submitBg: '#FFE433',
      submitHoverBg: '#ffe747',
      submitActiveBg: '#ffde05',
      submitDisabledBg: '#ffe747',
      submitDisabledColor: '#1C2331',
      // submitBorder: "#09080D",
      // submitBorderDisabled: "#",
      // submitBoxShadow: "#000",
      publicOffer: '#FFD699',
      linkColor: '#E50037',
      linkColorHover: '#fd4346',
      linkColorActive: '#e40307',
      loaderColor: '#FFE600',
      error: '#E05252',
      errorColorText: '#E8ECF2',
      errorPlaceholderColorText: '#88A2BF',
    },
  };
}

function thrippleSevenWhiteTheme() {
  return {
    dark: false,
    colors: {
      // primary: "#",
      // secondary: "#",
      color: '#09080D',
      background: '#fff',
      cardSecondaryColor: '#09080D',
      cardBg: '#FFF',
      inputColor: '#4A426B',
      inputLabel: '#4A426B',
      inputLabelAsteriks: '#E05252',
      inputPlaceholderColor: '#796EA6',
      inputBorder: '#D0CCE0',
      inputBorderFocused: '#6d619f',
      inputBorderError: '#D0CCE0',
      inputBackground: '#FFF',
      inputBackgroundError: '#FFF',
      inputBackgroundHover: '#FFF',
      inputBackgroundFocus: '#FFF',
      submitColor: '#09080D',
      submitBg: '#FFBD00',
      submitHoverBg: '#D19B00',
      submitActiveBg: '#9E7500',
      submitDisabledBg: '#FFE499',
      submitDisabledColor: '#B3ADCC',
      submitBorder: '#09080D',
      // submitBorderDisabled: "#",
      submitBoxShadow: '#000',
      publicOffer: '#796EA6',
      linkColor: '#6969cd',
      linkColorHover: '#5b5ba8',
      linkColorActive: '#8989d7',
      loaderColor: '#FFBD00',
      error: '#E05252',
      errorColorText: '#4A426B',
      errorPlaceholderColorText: '#796EA6',
    },
  };
}

function trippleSevenBlackTheme() {
  return {
    dark: true,
    colors: {
      // primary: "#",
      // secondary: "#",
      color: '#FFF',
      background: '#030508',
      cardSecondaryColor: '#FFF',
      cardBg: '#0D1826',
      inputColor: '#FFF',
      inputLabel: '#6B8DB3',
      inputLabelAsteriks: '#E05252',
      inputPlaceholderColor: '#88A2BF',
      inputBorder: '#796EA6',
      inputBorderFocused: '#a199c0',
      inputBorderError: '#796EA6',
      inputBackground: '#0D1826',
      inputBackgroundError: '#0D1826',
      inputBackgroundHover: '#0D1826',
      inputBackgroundFocus: '#0D1826',
      submitColor: '#09080D',
      submitBg: '#FFBD00',
      submitHoverBg: '#D19B00',
      submitActiveBg: '#9E7500',
      submitDisabledBg: '#FFE499',
      submitDisabledColor: '#B3ADCC',
      submitBorder: '#09080D',
      // submitBorderDisabled: "#",
      // submitBoxShadow: "",
      publicOffer: '#FFDD00',
      linkColor: '#FC1418',
      linkColorHover: '#fd4346',
      linkColorActive: '#e40307',
      loaderColor: '#F1C21C',
      error: '#E05252',
      errorColorText: '#FFF',
      errorPlaceholderColorText: '#88A2BF',
    },
  };
}

function jackpottRabbitTheme() {
  return {
    dark: true,
    colors: {
      // primary: "#",
      // secondary: "#",
      color: '#FFF',
      background: '#0B080C',
      cardSecondaryColor: '#FFF',
      cardBg: '#35293D',
      inputColor: '#FFF',
      inputLabel: '#FFF',
      inputLabelAsteriks: '#FF0101',
      inputPlaceholderColor: '#C0AFCA',
      inputBorder: '#9072A1',
      inputBorderFocused: '#ac95b8',
      inputBorderError: '#9072A1',
      inputBackground: '#1C141F',
      inputBackgroundError: '#1C141F',
      inputBackgroundHover: '#1C141F',
      inputBackgroundFocus: '#1C141F',
      submitColor: '#0B080C',
      submitBg: '#EBFF07',
      submitHoverBg: '#d9ec00',
      submitActiveBg: '#c1d200',
      submitDisabledBg: '#626B00',
      submitDisabledColor: '#35293D',
      // submitBorder: "",
      // submitBorderDisabled: "#",
      // submitBoxShadow: "",
      publicOffer: '#C0AFCA',
      linkColor: '#FFF',
      linkColorHover: '#e6e6e6',
      linkColorActive: '#CCC',
      loaderColor: '#EBFF07',
      error: '#FF0101',
      errorColorText: '#FFF',
      errorPlaceholderColorText: '#C0AFCA',
    },
  };
}

function nlcTheme() {
  return {
    dark: true,
    colors: {
      // primary: "#",
      // secondary: "#",
      color: '#FFF',
      background: '#141722',
      cardSecondaryColor: '#FFF',
      cardBg: '#23293B',
      inputColor: '#FFF',
      inputLabel: '#FFF',
      inputLabelAsteriks: '#E05252',
      inputPlaceholderColor: '#9AA0B1',
      inputBorder: '#9AA0B1',
      inputBorderFocused: '#cccfd8',
      inputBorderError: '#9AA0B1',
      inputBackground: '#141722',
      inputBackgroundError: '#141722',
      inputBackgroundHover: '#141722',
      inputBackgroundFocus: '#141722',
      submitColor: '#FFF',
      submitBg: '#F31D53',
      submitHoverBg: '#e80c44',
      submitActiveBg: '#b50a35',
      submitDisabledBg: '#fff499',
      submitDisabledColor: '#FFF',
      // submitBorder: "",
      // submitBorderDisabled: "#",
      // submitBoxShadow: "",
      publicOffer: '#9AA0B1',
      linkColor: '#6969cd',
      linkColorHover: '#5b5ba8',
      linkColorActive: '#8989d7',
      loaderColor: '#F1C21C',
      error: '#E05252',
      errorColorText: '#FFF',
      errorPlaceholderColorText: '#9AA0B1',
    },
  };
}

function nlcV2Theme() {
  return {
    dark: true,
    colors: {
      // primary: "#",
      // secondary: "#",
      color: '#FFF',
      background: '#0b1020',
      cardSecondaryColor: '#FFF',
      cardBg: '#23293B',
      inputColor: '#FAF8FF',
      inputLabel: '#8B90A5',
      inputLabelAsteriks: '#E05252',
      inputPlaceholderColor: '#A6AAC0',
      inputBorder: '#A6AAC0',
      inputBorderFocused: '#cccfd8',
      inputBorderError: '#96323e',
      inputBackground: '#202536',
      inputBackgroundError: '#2c0007',
      inputBackgroundHover: '#141722',
      inputBackgroundFocus: '#141722',
      submitColor: '#FFF',
      submitBg: '#F31D53',
      submitHoverBg: '#e80c44',
      submitActiveBg: '#b50a35',
      submitDisabledBg: '#fff499',
      submitDisabledColor: '#FFF',
      // submitBorder: "",
      // submitBorderDisabled: "#",
      // submitBoxShadow: "",
      publicOffer: '#9AA0B1',
      linkColor: '#6969cd',
      linkColorHover: '#5b5ba8',
      linkColorActive: '#8989d7',
      loaderColor: '#1694F8',
      error: '#BB1522',
      errorColorText: '#FAF8FF',
      errorPlaceholderColorText: '#A6AAC0',
    },
  };
}

function fortuneWheelzTheme() {
  return {
    dark: true,
    colors: {
      // primary: "#",
      // secondary: "#",
      color: '#F8FAFC',
      background: '#0E201D',
      cardSecondaryColor: '#F8FAFC',
      cardBg: '#538969',
      inputColor: '#02170F',
      inputLabel: '#F8FAFC',
      inputLabelAsteriks: '#E05252',
      inputPlaceholderColor: '#64868B',
      inputBorder: '#CBE1DD',
      inputBorderFocused: '#538969',
      inputBorderError: '#CBE1DD',
      inputBackground: '#F8FAFC',
      inputBackgroundError: '#F8FAFC',
      inputBackgroundHover: '#F8FAFC',
      inputBackgroundFocus: '#F8FAFC',
      submitColor: '#02170F',
      submitBg: '#F9D01A',
      submitHoverBg: '#e3bb05',
      submitActiveBg: '#c8a405',
      submitDisabledBg: '#B39305',
      submitDisabledColor: '#594902',
      // submitBorder: "#09080D",
      // submitBorderDisabled: "#",
      // submitBoxShadow: "#000",
      publicOffer: '#64868B',
      linkColor: '#6969cd',
      linkColorHover: '#5b5ba8',
      linkColorActive: '#8989d7',
      loaderColor: '#F9D01A',
      error: '#E05252',
      errorColorText: '#02170F',
      errorPlaceholderColorText: '#',
    },
  };
}

function fortuneWheelzVer2Theme() {
  return {
    dark: true,
    colors: {
      // // primary: "#",
      // // secondary: "#",
      color: '#FFF',
      background: '#001212',
      cardSecondaryColor: '#AABDBD',
      cardBg: '#003333',
      inputColor: '#D0DADA',
      inputLabel: '#608282',
      // inputLabelAsteriks: '#E05252',
      inputPlaceholderColor: '#859F9F',
      inputBorder: '#144343',
      inputBorderFocused: '#1e6464',
      inputBorderError: '#7D0F00',
      inputBackground: '#003333',
      // inputBackgroundError: '#',
      inputBackgroundHover: '#003333',
      inputBackgroundFocus: '#003333',
      submitColor: '#391800',
      submitBg: '#FFB902',
      submitHoverBg: '#ffe403',
      submitActiveBg: '#ffb902',
      submitDisabledBg: '#FFB902',
      submitDisabledColor: '#FFB902',
      submitBorder: '#ffffff29',
      submitBorderDisabled: '#ffffff29',
      // // submitBoxShadow: "#000",
      publicOffer: '#AABDBD',
      linkColor: '#859F9F',
      linkColorHover: '#91a9a9',
      linkColorActive: '#aabcbc',
      loaderColor: '#FFEA30',
      error: '#BB2815',
      errorColorText: '#BB2815',
      errorPlaceholderColorText: '#859F9F',
    },
  };
}

function vegasBlackTheme() {
  return {
    dark: true,
    colors: {
      // primary: "#",
      // secondary: "#",
      color: '#FFF',
      background: '#001B24',
      cardSecondaryColor: '#FFF',
      cardBg: '#142d35',
      inputColor: '#FFF',
      inputLabel: '#66D9FF',
      // inputLabelAsteriks: "#E05252",
      inputPlaceholderColor: '#00BFFF',
      // inputBorder: "",
      // inputBorderFocused: "",
      // inputBorderError: "#",
      inputBackground: '#142d35',
      inputBackgroundError: '#270702',
      inputBackgroundHover: '#142d35',
      inputBackgroundFocus: '#32484f',
      submitColor: '#FFF',
      submitBg: '#FF2B39',
      submitHoverBg: '#FF2B39',
      submitActiveBg: '#CC000D',
      submitDisabledBg: '#004D66',
      submitDisabledColor: '#0086B2',
      submitBorder: '#FF2B39',
      submitBorderDisabled: '#004D66',
      // submitBoxShadow: "#000",
      publicOffer: '#0086B2',
      linkColor: '#FF3340',
      linkColorHover: '#fd4346',
      linkColorActive: '#e40307',
      loaderColor: '#FFE600',
      error: '#F66751',
      errorColorText: '#FFF',
      errorPlaceholderColorText: '#F66751',
    },
  };
}

function wildWinzBlackTheme() {
  return {
    dark: true,
    colors: {
      // primary: "#",
      // secondary: "#",
      color: '#FFF',
      background: '#111218',
      cardSecondaryColor: '#111218',
      cardBg: '#44485F',
      inputColor: '#F3F4F7',
      inputLabel: '#8E92AE',
      inputLabelAsteriks: '#FF2200',
      inputPlaceholderColor: '#8E92AE',
      inputBorder: '#2F3242',
      inputBorderFocused: '#A23ADF',
      inputBorderError: '#2F3242',
      inputBackground: '#1E1F2A',
      inputBackgroundError: '#1E1F2A',
      inputBackgroundHover: '#1E1F2A',
      inputBackgroundFocus: '#1E1F2A',
      submitColor: '#111218',
      submitBg: '#33FF44',
      submitHoverBg: '#33FF44',
      submitActiveBg: '#33FF44',
      submitDisabledBg: '#44485F',
      submitDisabledColor: '#8E92AE',
      // submitBorder: "#",
      // submitBorderDisabled: "#",
      // submitBoxShadow: "#",
      publicOffer: '#FFF',
      linkColor: '#F3F4F7',
      linkColorHover: '#d6d9e3',
      linkColorActive: '#e6e8ee',
      loaderColor: '#F3F4F7',
      error: '#FF2200',
      errorColorText: '#FF2200',
      errorPlaceholderColorText: '#8E92AE',
    },
  };
}

function fcityTheme() {
  return {
    dark: true,
    colors: {
      // primary: "#",
      // secondary: "#",
      color: '#FFF',
      background: '#000A3F',
      cardSecondaryColor: '#B4B6F0',
      cardBg: '#0D1F80',
      inputColor: '#000A3F',
      inputLabel: '#FFF',
      inputLabelAsteriks: '#D90135',
      inputPlaceholderColor: '#1838E5',
      inputBorder: '#8185EB',
      inputBorderFocused: '#6267e6',
      inputBorderError: '#D90135',
      inputBackground: '#FFF',
      inputBackgroundError: '#FFF',
      inputBackgroundHover: '#FFF',
      inputBackgroundFocus: '#FFF',
      submitColor: '#000A3F',
      submitBg: '#00FF93',
      submitHoverBg: '#00BF6E',
      submitActiveBg: '#00ac63',
      submitDisabledBg: '#009958',
      submitDisabledColor: '#000A3F',
      submitBorder: '#8185EB',
      submitBorderDisabled: '#8185EB',
      // submitBoxShadow: "#",
      publicOffer: '#FFF',
      linkColor: '#FF11F9',
      linkColorHover: '#FF2BB0',
      linkColorActive: '#A5086B',
      loaderColor: '#00FF93',
      error: '#D90135',
      errorColorText: '#000A3F',
      errorPlaceholderColorText: '#1838E5',
    },
  };
}

function funrizeBlackTheme() {
  return {
    dark: true,
    colors: {
      // // primary: "#",
      // // secondary: "#",
      color: '#FFF',
      background: '#0D0D2E',
      cardSecondaryColor: '#A5A5D9',
      cardBg: '#6D6D93',
      inputColor: '#F8F8FF',
      inputLabel: '#8787AC',
      // inputLabelAsteriks: '#',
      inputPlaceholderColor: '#A5A5D9',
      inputBorder: '#3E3E61',
      inputBorderFocused: '#A5A5D9',
      inputBorderError: '#5E0217',
      inputBackground: '#161740',
      inputBackgroundError: '#33032F',
      inputBackgroundHover: '#161740',
      inputBackgroundFocus: '#161740',
      submitColor: '#FFF',
      submitBg: '#F805AF',
      submitHoverBg: '#fb35c0',
      submitActiveBg: '#fa10b4',
      submitDisabledBg: '#F805AF',
      submitDisabledColor: '#FFF',
      // submitBorder: "#",
      // submitBorderDisabled: "#",
      // submitBoxShadow: "#",
      publicOffer: '#6D6D93',
      linkColor: '#8B86F4',
      linkColorHover: '#a29ef6',
      linkColorActive: '#918cf5',
      loaderColor: '#8B86F4',
      error: '#CC0031',
      errorColorText: '#CC0031',
      errorPlaceholderColorText: '#A5A5D9',
    },
  };
}
